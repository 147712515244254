import React from 'react';
import styles from './app.module.scss';
import {
  PlayArea,
  Player,
  ShadeMap,
  BeastMap,
  Cave,
  StatsDashboard,
  Backdrop,
  TextPanel,
  Intro,
} from './components';
import { useAppContext, Transition } from './context/app-context/app-context';

const Game = () => {
  return (
    <>
      <TextPanel />
      <StatsDashboard />
      <PlayArea />
      <BeastMap />
      <ShadeMap />
      <Backdrop />
      <Cave />
      <Player />
    </>
  );
};

const App = () => {
  const { transition } = useAppContext();
  const transitionComponent = React.useMemo(() => {
    switch (transition) {
      case Transition.MODE: {
        return <Intro />;
      }
      case Transition.PRE_GAME_BUILD:
      case Transition.GAME_START:
      case Transition.GAME_ACTIVE: {
        return <Game />;
      }
    }
  }, [transition]);
  return <div className={styles.app}>{transitionComponent}</div>;
};

export default App;
