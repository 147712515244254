import * as React from 'react';
import { useCaveContext } from '../../context/cave-context/cave-context';
import { FadeBlock } from '../fade-behind/fade-behind';
import { CHARACTER } from '../../utility';

export const Backdrop = () => {
  const { isCaveEntered } = useCaveContext();

  return isCaveEntered ? (
    <FadeBlock
      color='black'
      fullscreen
      backdrop
      gradient={10}
      duration={10}
      type='forward'
    />
  ) : null;
};
