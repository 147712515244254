import * as React from 'react';
import { FadeBlock } from '../../components/fade-behind/fade-behind';
import { CHARACTER, FRAME_LIMITER_MS } from '../../utility';
import { throttle, debounce, noop } from 'lodash';
import { useMountEffect } from '../../hooks';
import { useAppContext, Transition } from '../app-context/app-context';
import { callbackify } from 'util';

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type FadeType = 'forward' | 'reverse';
export type DurationOrGradient = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
export type Color = 'green' | 'red' | 'black' | 'blue' | 'brown' | 'white';

export interface IShakeOptions {
  color?: Color;
  type?: FadeType;
  duration?: DurationOrGradient;
  gradient?: DurationOrGradient;
  callback?: (...args: any[]) => void;
}

interface IScreenShakeContext {
  shakeColor: (color: Color, options?: IShakeOptions) => void;
}

const ScreenShakeContext = React.createContext<IScreenShakeContext>({
  shakeColor: (_color: Color, _options?: IShakeOptions) => {},
});

export const useScreenShakeContext = () => React.useContext(ScreenShakeContext);

export const ScreenShakeContextProvider: React.FC<{
  children: React.ReactChild | React.ReactChild[];
}> = React.memo(({ children }) => {
  const [key, updateKey] = React.useState<number | undefined>(undefined);
  const [options, updateOptions] = React.useState<IShakeOptions>({
    color: 'black',
  });
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  const shakeColor = React.useMemo(
    () =>
      debounce(
        (color: Color, options?: Omit<IShakeOptions, 'color'>) => {
          // console.log(`shake ${color}`);
          updateKey(+new Date());
          updateOptions({ ...options, color });
        },
        FRAME_LIMITER_MS,
        { leading: true, trailing: false }
      ),
    []
  );

  const memoizedOptions = React.useMemo<
    PartialBy<Required<IShakeOptions>, 'callback'>
  >(
    () => ({
      color: options.color || 'black',
      type: options.type || 'reverse',
      gradient: options.gradient || 10,
      duration: options.duration || 10,
      callback: options.callback,
    }),
    [options]
  );

  useMountEffect(() => {
    timeoutRef.current = setTimeout(() => {
      updateKey(undefined);
      updateOptions({});
      if (memoizedOptions.callback) {
        memoizedOptions.callback();
      }
    }, memoizedOptions.duration * 1000 || FRAME_LIMITER_MS);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [key, memoizedOptions, timeoutRef]);

  React.useEffect(() => {
    return () => {
      shakeColor.cancel();
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  });

  return (
    <ScreenShakeContext.Provider value={{ shakeColor }}>
      {key ? (
        <FadeBlock
          color={memoizedOptions.color}
          type={memoizedOptions.type}
          gradient={memoizedOptions.gradient}
          duration={memoizedOptions.duration}
          key={key}
          fullscreen
        />
      ) : null}
      {children}
    </ScreenShakeContext.Provider>
  );
});
