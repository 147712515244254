// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes text-panel_fade-text__3QhkG {\n  0% {\n    color: white; }\n  70% {\n    color: white; }\n  100% {\n    color: transparent; } }\n\n.text-panel_textPanel__1ctbo {\n  display: flex;\n  position: absolute;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n  background-color: transparent;\n  width: 40px;\n  height: 40px;\n  z-index: 99999999;\n  margin-top: 50px;\n  animation-duration: 10s;\n  animation-name: text-panel_fade-text__3QhkG; }\n", ""]);
// Exports
exports.locals = {
	"textPanel": "text-panel_textPanel__1ctbo",
	"fade-text": "text-panel_fade-text__3QhkG"
};
module.exports = exports;
