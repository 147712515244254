import * as React from 'react';
import {
  moveTileLeft,
  moveTileUp,
  CENTER,
  moveTileRight,
  isTileSame,
} from '../../utility';
import { ITilePosition } from '../../interfaces.interface';
import { useMemoizedValue, useMountEffect, useNewGame } from '../../hooks';

export const BrownTilesAbove = [
  moveTileLeft(CENTER, 2),
  moveTileUp(CENTER),
  moveTileUp(moveTileRight(CENTER)),
  moveTileUp(moveTileLeft(CENTER)),
  moveTileRight(CENTER, 2),
];

export const BlackTilesAbove = [
  moveTileLeft(CENTER),
  CENTER,
  moveTileRight(CENTER),
];

export const CAVE_LENGTH = 7;
export const WIZARD_TILE = moveTileUp(CENTER, CAVE_LENGTH - 1);

export const BrownTilesBelow = [
  ...Array(CAVE_LENGTH)
    .fill(0)
    .map((_, idx) => moveTileUp(moveTileLeft(CENTER, 2), idx + 1)),
  ...Array(CAVE_LENGTH)
    .fill(0)
    .map((_, idx) => moveTileUp(moveTileRight(CENTER, 2), idx + 1)),
  moveTileUp(moveTileLeft(CENTER, 1), CAVE_LENGTH),
  moveTileUp(moveTileRight(CENTER, 1), CAVE_LENGTH),
  moveTileUp(moveTileLeft(CENTER, 1), CAVE_LENGTH + 1),
  moveTileUp(moveTileRight(CENTER, 1), CAVE_LENGTH + 1),
  moveTileUp(CENTER, CAVE_LENGTH + 1),
];

export const BrownTilesInside = [
  ...Array(CAVE_LENGTH - 1)
    .fill(0)
    .map((_, idx) => moveTileUp(moveTileLeft(CENTER, 1), idx + 2)),
  ...Array(CAVE_LENGTH - 1)
    .fill(0)
    .map((_, idx) => moveTileUp(moveTileRight(CENTER, 1), idx + 2)),
  ...Array(CAVE_LENGTH - 1)
    .fill(0)
    .map((_, idx) => moveTileUp(CENTER, idx + 2)),
  moveTileUp(CENTER, CAVE_LENGTH + 1),
];

export const CaveLightTilesInside = [
  ...Array(CAVE_LENGTH - 1)
    .fill(0)
    .map((_, idx) => {
      if (idx % 2) {
        return moveTileUp(moveTileLeft(CENTER, 1), idx + 1);
      }
    }),
  ...Array(CAVE_LENGTH - 1)
    .fill(0)
    .map((_, idx) => {
      if (idx % 2) {
        return moveTileUp(moveTileRight(CENTER, 1), idx + 1);
      }
    }),
  ...Array(CAVE_LENGTH - 1)
    .fill(0)
    .map((_, idx) => {
      if (idx % 2) {
        return moveTileUp(CENTER, idx + 2);
      }
    }),
];

const CAVE_SPAWN_RATE = 100;

interface ICaveContext {
  isCaveTile: (position: ITilePosition) => boolean;
  isWizardTile: (position: ITilePosition) => boolean;
  isCaveEntrance: (position: ITilePosition) => boolean;
  isCaveExit: (position: ITilePosition) => boolean;
  spawnCaveIfReady: (steps: number) => void;
  enterCave: () => void;
  exitCave: () => void;
  isCaveVisible: boolean;
  isCaveEntered: boolean;
  updateCaveState: (steps: number, position: ITilePosition) => void;
  cavesEntered: number;
  wizardsEncountered: number;
}

const CaveContext = React.createContext<ICaveContext>({
  isCaveTile: (_: ITilePosition) => false,
  isWizardTile: (_: ITilePosition) => false,
  isCaveEntrance: (_: ITilePosition) => false,
  isCaveExit: (_: ITilePosition) => false,
  spawnCaveIfReady: (_: number) => {},
  updateCaveState: (steps: number, position: ITilePosition) => {},
  enterCave: () => {},
  exitCave: () => {},
  isCaveVisible: false,
  isCaveEntered: false,
  cavesEntered: 0,
  wizardsEncountered: 0,
});

export const useCaveContext = () => React.useContext(CaveContext);

export const CaveContextProvider: React.FC<{
  children: React.ReactChild | React.ReactChild[];
}> = React.memo(({ children }) => {
  const [isCaveVisible, updateCaveVisibility] = React.useState(false);
  const [isCaveEntered, updateCaveEntered] = React.useState(false);
  const [cavesEntered, updateCavesEntered] = React.useState<number>(0);
  const [wizardsEncountered, updatewizardsEncountered] = React.useState<number>(
    0
  );
  const allowableHealthGain = React.useRef<number>(0);

  useNewGame(() => {
    updatewizardsEncountered(0);
    updateCaveVisibility(false);
    updateCavesEntered(0);
    updateCaveEntered(false);
  });

  const spawnCaveIfReady = React.useCallback(
    (steps) => {
      if (steps > 0 && steps % CAVE_SPAWN_RATE == 0) {
        // console.log('SPAWNING CAVE');
        updateCaveVisibility(true);
      }
    },
    [updateCaveVisibility]
  );

  const enterCave = React.useCallback(() => {
    // console.log('ENTER CAVE');
    updateCaveEntered(true);
  }, [updateCaveEntered]);

  const exitCave = React.useCallback(() => {
    // console.log('EXIT CAVE');
    updateCaveVisibility(false);
    updateCaveEntered(false);
  }, [updateCaveVisibility, updateCaveEntered]);

  useMountEffect(() => {
    if (isCaveEntered) {
      updateCavesEntered((prev) => prev + 1);
      allowableHealthGain.current += 1;
    } else {
      allowableHealthGain.current = 0;
    }
  }, [isCaveEntered]);

  const isCaveEntrance = React.useCallback(
    (position: ITilePosition): boolean => {
      if (!isCaveVisible || isCaveEntered) {
        return false;
      }

      for (let i = 0; i < BlackTilesAbove.length; ++i) {
        if (isTileSame(position, BlackTilesAbove[i])) {
          return true;
        }
      }

      return false;
    },
    [isCaveVisible, isCaveEntered]
  );

  const isCaveExit = React.useCallback(
    (position: ITilePosition): boolean => {
      if (!isCaveEntered) {
        return false;
      }

      for (let i = 0; i < BlackTilesAbove.length; ++i) {
        if (isTileSame(position, BlackTilesAbove[i])) {
          return true;
        }
      }

      return false;
    },
    [isCaveEntered]
  );

  const isCaveTile = React.useCallback(
    (position: ITilePosition): boolean => {
      if (!isCaveVisible) {
        return false;
      }

      if (!isCaveEntered) {
        for (let i = 0; i < BrownTilesAbove.length; ++i) {
          if (isTileSame(position, BrownTilesAbove[i])) {
            return true;
          }
        }
      } else {
        for (let i = 0; i < BrownTilesBelow.length; ++i) {
          if (isTileSame(position, BrownTilesBelow[i])) {
            return true;
          }
        }
      }

      return false;
    },
    [isCaveVisible, isCaveEntered]
  );

  const isWizardTile = React.useCallback(
    (position: ITilePosition) => {
      if (isCaveEntered && allowableHealthGain.current > 0) {
        if (position.Y === WIZARD_TILE.Y - 1) {
          allowableHealthGain.current = 0;
          updatewizardsEncountered((prev) => prev + 1);
          return true;
        }
      }
      return false;
    },
    [isCaveEntered, allowableHealthGain]
  );

  const updateCaveState = React.useCallback(
    (steps: number, position: ITilePosition) => {
      spawnCaveIfReady(steps);

      if (isCaveEntrance(position)) {
        enterCave();
      } else if (isCaveExit(position)) {
        exitCave();
      }
    },
    [spawnCaveIfReady, isCaveEntrance, isCaveExit, enterCave, exitCave]
  );

  return (
    <CaveContext.Provider
      value={useMemoizedValue({
        isCaveTile,
        isWizardTile,
        isCaveEntrance,
        isCaveExit,
        spawnCaveIfReady,
        enterCave,
        exitCave,
        updateCaveState,
        isCaveVisible,
        isCaveEntered,
        cavesEntered,
        wizardsEncountered,
      })}
    >
      {children}
    </CaveContext.Provider>
  );
});
