import * as React from 'react';
import { useMemoizedValue } from '../../hooks';

interface ITextPanelContext {
  text: string[];
  updateText: (text: string[]) => void;
}

const TextPanelContext = React.createContext<ITextPanelContext>({
  text: [],
  updateText: (text: string[]) => {},
});

export const useTextPanelContext = () => React.useContext(TextPanelContext);

export const TextPanelContextProvider: React.FC<{
  children: React.ReactChild | React.ReactChild[];
}> = React.memo(({ children }) => {
  const [text, _updateText] = React.useState<string[]>([]);

  const updateText = React.useCallback(
    (text: string[]) => {
      _updateText(text);

      setTimeout(() => {
        _updateText([]);
      }, 10 * 1000);
    },
    [_updateText]
  );

  return (
    <TextPanelContext.Provider value={useMemoizedValue({ text, updateText })}>
      {children}
    </TextPanelContext.Provider>
  );
});
