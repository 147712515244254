import * as React from 'react';
import { PLAY_AREA_DIM, CHARACTER_DIM, NUM_GRID_TILES } from '../../utility';
import { debounce } from 'lodash';
import { useMemoizedValue, useMountEffect } from '../../hooks';
import { ITilePosition, IPXPosition } from '../../interfaces.interface';

interface ILayoutContext {
  maxAreaTop: number;
  maxAreaBottom: number;
  maxAreaLeft: number;
  maxAreaRight: number;
  playAreaLength: number;
  layoutDidUpdate: boolean;
  characterWith: number;
  playAreaTileLength: number;
  isTileWithinBounds: (position: ITilePosition) => boolean;
}

const LayoutContext = React.createContext<ILayoutContext>({
  maxAreaTop: 0,
  maxAreaBottom: 0,
  maxAreaLeft: 0,
  maxAreaRight: 0,
  playAreaLength: 0,
  layoutDidUpdate: false,
  characterWith: 0,
  playAreaTileLength: 0,
  isTileWithinBounds: (_: ITilePosition) => false,
});

export const useLayoutContext = () => React.useContext(LayoutContext);

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );

  const onResize = React.useCallback(() => {
    setWindowDimensions(getWindowDimensions());
  }, [setWindowDimensions]);

  React.useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [onResize]);

  return windowDimensions;
};

const WIN_ADJUST_RATIO = 0.75;
const getWindowMaxLength = (w: number, h: number) =>
  Math.floor(Math.min(w * WIN_ADJUST_RATIO, h * WIN_ADJUST_RATIO));

export const usePlayAreaBounds = () => {
  const { height: wHeight, width: wWidth } = useWindowDimensions();

  const maxAreaBottom = React.useMemo(
    () =>
      Math.floor((wHeight - PLAY_AREA_DIM) / (2 * CHARACTER_DIM)) *
      CHARACTER_DIM,
    [wHeight]
  );

  const maxAreaLeft = React.useMemo(
    () =>
      Math.floor((wWidth - PLAY_AREA_DIM) / (2 * CHARACTER_DIM)) *
      CHARACTER_DIM,
    [wWidth]
  );

  const maxAreaTop = React.useMemo(() => maxAreaBottom + PLAY_AREA_DIM, [
    maxAreaBottom,
  ]);

  const maxAreaRight = React.useMemo(() => maxAreaLeft + PLAY_AREA_DIM, [
    maxAreaLeft,
  ]);

  // console.log(`PlAY AREA BOUNDS: T${top}, B${bottom}, L${left}, R${right}`);

  return { maxAreaTop, maxAreaLeft, maxAreaBottom, maxAreaRight };
};

interface IWindowBounds {
  maxAreaTop: number;
  maxAreaBottom: number;
  maxAreaLeft: number;
  maxAreaRight: number;
}
const PLAY_AREA_TILE_LEN = 50;

export const LayoutContextProvider: React.FC<{
  children: React.ReactChild | React.ReactChild[];
}> = React.memo(({ children }) => {
  const {
    maxAreaTop,
    maxAreaLeft,
    maxAreaBottom,
    maxAreaRight,
  } = usePlayAreaBounds();
  const { height: wHeight, width: wWidth } = useWindowDimensions();
  const [layoutDidUpdate, updateLayout] = React.useState(false);
  const [characterWidth, updateCharacterWidth] = React.useState(0);
  const pxToTile = React.useCallback(
    (position: IPXPosition) => {
      const tileX = Math.floor(position.X - maxAreaLeft) / CHARACTER_DIM;
      const tileY = Math.floor(position.Y - maxAreaBottom) / CHARACTER_DIM;
      const tilePX =
        Math.floor((position.PX || position.X) - maxAreaLeft) / CHARACTER_DIM;
      const tilePY =
        Math.floor((position.PY || position.Y) - maxAreaBottom) / CHARACTER_DIM;

      return { X: tileX, Y: tileY, PX: tilePX, PY: tilePY };
    },
    [maxAreaLeft, maxAreaBottom]
  );

  const tileToPx = React.useCallback(
    (position: ITilePosition) => {
      const tileX = Math.floor(position.X * CHARACTER_DIM) + maxAreaLeft;
      const tileY = Math.floor(position.Y * CHARACTER_DIM) + maxAreaBottom;
      const tilePX =
        Math.floor((position.PX || position.X) * CHARACTER_DIM) + maxAreaLeft;
      const tilePY =
        Math.floor((position.PY || position.Y) * CHARACTER_DIM) + maxAreaBottom;

      return { X: tileX, Y: tileY, PX: tilePX, PY: tilePY };
    },
    [maxAreaLeft, maxAreaBottom]
  );

  const TOP_RIGHT_CORNER = React.useRef<ITilePosition>(
    pxToTile({ X: maxAreaRight, Y: maxAreaTop })
  );
  const BOTTOM_LEFT_CORNER = React.useRef<ITilePosition>(
    pxToTile({ X: maxAreaLeft, Y: maxAreaBottom })
  );

  const isTileWithinBounds = React.useCallback(
    (position: ITilePosition) =>
      position.X >= BOTTOM_LEFT_CORNER.current.X &&
      position.X < TOP_RIGHT_CORNER.current.X &&
      position.Y >= BOTTOM_LEFT_CORNER.current.Y &&
      position.Y < TOP_RIGHT_CORNER.current.Y,
    [TOP_RIGHT_CORNER, BOTTOM_LEFT_CORNER]
  );

  const _updateCharacterWidth = React.useCallback(() => {}, [
    maxAreaTop,
    maxAreaLeft,
  ]);

  const shiftLayout = React.useMemo(
    () =>
      debounce(
        (didShift: boolean = true) => {
          updateLayout(didShift);
        },
        100,
        { leading: false, trailing: true }
      ),
    []
  );

  useMountEffect(() => {
    shiftLayout();

    return () => {
      shiftLayout.cancel();
    };
  }, [maxAreaTop, maxAreaLeft]);

  React.useEffect(() => {
    if (layoutDidUpdate) {
      shiftLayout(false);
    }
  }, [layoutDidUpdate]);

  const playAreaLength = React.useMemo(
    () => Math.abs(maxAreaLeft - maxAreaRight),
    [maxAreaLeft, maxAreaRight]
  );

  return (
    <LayoutContext.Provider
      value={useMemoizedValue({
        maxAreaTop,
        maxAreaLeft,
        maxAreaBottom,
        maxAreaRight,
        playAreaLength,
        layoutDidUpdate,
        characterWith: CHARACTER_DIM,
        playAreaTileLength: NUM_GRID_TILES,
        isTileWithinBounds,
      })}
    >
      {children}
    </LayoutContext.Provider>
  );
});
