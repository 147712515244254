import * as React from 'react';
import { ITilePosition } from '../../interfaces.interface';
import { CENTER, isTileSame, FRAME_LIMITER_MS } from '../../utility';
import { debounce } from 'lodash';
import { useMemoizedValue, useMountEffect, useNewGame } from '../../hooks';
import { useCaveContext } from '../cave-context/cave-context';
import {
  useScreenShakeContext,
  Color,
  IShakeOptions,
} from '../screen-shake-context/screen-shake-context';
import { useLayoutContext } from '../layout-context/layout-context';
import { useShadeContext } from '../shade-context/shade-context';
import {
  useAppContext,
  Transition,
  PLAYER_READY,
} from '../app-context/app-context';

interface IPlayerContext {
  attackPlayer: (count: number, color: Color, options?: IShakeOptions) => void;
  updatePlayerPosition: (position: ITilePosition, force?: boolean) => void;
  isPlayerTile: (position: ITilePosition) => boolean;
  playerMoveCount: number;
  playerHealth: number;
  playerPosition: ITilePosition;
}

const PlayerContext = React.createContext<IPlayerContext>({
  attackPlayer: (_count: number, _color: Color, _options?: IShakeOptions) => {},
  updatePlayerPosition: (_: ITilePosition, force?: boolean) => {},
  isPlayerTile: (_: ITilePosition) => false,
  playerMoveCount: 0,
  playerHealth: 0,
  playerPosition: CENTER,
});

export const usePlayerContext = () => React.useContext(PlayerContext);

export const PlayerContextProvider: React.FC<{
  children: React.ReactChild | React.ReactChild[];
}> = React.memo(({ children }) => {
  const {
    updateCaveState,
    isCaveTile,
    isWizardTile,
    isCaveEntered,
  } = useCaveContext();
  const { resetGame, transition, setReady, onGameActive } = useAppContext();
  const { isTileWithinBounds } = useLayoutContext();
  const { tileHasShade } = useShadeContext();
  const { shakeColor } = useScreenShakeContext();
  const [playerHealth, updatePlayerHealth] = React.useState<number>(3);
  const playerHealthRef = React.useRef<number>(3);
  const playerPositionRef = React.useRef<ITilePosition>(CENTER);
  const [playerPosition, _updatePlayerPosition] = React.useState<ITilePosition>(
    playerPositionRef.current
  );

  const playerMoveCount = React.useRef<number>(0);

  useNewGame(() => {
    updatePlayerHealth(3);
    updatePlayerPosition(CENTER);
    playerPositionRef.current = CENTER;
    playerMoveCount.current = 0;
    playerHealthRef.current = 3;
  });

  React.useEffect(() => {
    if (
      isTileSame(playerPosition, CENTER) &&
      transition === Transition.PRE_GAME_BUILD
    ) {
      setReady(PLAYER_READY);
    }
  }, [playerPosition, transition]);

  const attackPlayer = React.useCallback(
    (count: number, color: Color, options?: IShakeOptions) => {
      if (count > 0) {
        shakeColor(color, options);

        updatePlayerHealth((prev) => prev - count);
        playerHealthRef.current -= count;
      }
    },
    [playerHealth, updatePlayerHealth, resetGame, shakeColor]
  );

  const isPlayerTile = React.useCallback(
    (position: ITilePosition) =>
      isTileSame(position, playerPositionRef.current),
    [playerPositionRef]
  );

  const updatePlayerPosition = React.useMemo(
    () =>
      debounce(
        (position: ITilePosition, force: boolean = false) => {
          if (
            ((!isTileSame(playerPositionRef.current, position) &&
              !isCaveTile(position)) ||
              force) &&
            isTileWithinBounds(position)
          ) {
            // console.log(`Player -> ${JSON.stringify(position, undefined, 2)}`);
            if (!isCaveEntered) {
              playerMoveCount.current++;
            }

            playerPositionRef.current = position;
            _updatePlayerPosition(position);
            updateCaveState(playerMoveCount.current, position);

            if (isWizardTile(position)) {
              updatePlayerHealth((prev) => prev + 1);
              playerHealthRef.current += 1;
              shakeColor('blue');
            } else if (tileHasShade(position)) {
              attackPlayer(1, 'black', { duration: 10 });
            }

            if (transition === Transition.GAME_START) {
              onGameActive();
            }
          }
        },
        FRAME_LIMITER_MS,
        {
          leading: true,
          trailing: false,
        }
      ),
    [
      _updatePlayerPosition,
      updateCaveState,
      isCaveTile,
      playerMoveCount,
      playerPositionRef,
      isCaveEntered,
      attackPlayer,
      transition,
    ]
  );

  useMountEffect(() => {
    return () => {
      updatePlayerPosition.cancel();
    };
  }, []);

  React.useEffect(() => {
    if (playerHealthRef.current <= 0 && transition === Transition.GAME_ACTIVE) {
      resetGame();
    }
  }, [playerHealth, transition]);

  return (
    <PlayerContext.Provider
      value={useMemoizedValue({
        attackPlayer,
        updatePlayerPosition,
        playerHealth: playerHealth,
        playerMoveCount: playerMoveCount.current,
        playerPosition,
        isPlayerTile,
      })}
    >
      {children}
    </PlayerContext.Provider>
  );
});
