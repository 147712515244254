import * as React from 'react';
import { throttle } from 'lodash';
import {
  KEYCODE,
  CHARACTER,
  FRAME_LIMITER_MS,
  CENTER,
  DIRECTION,
} from '../../utility';
import { ITilePosition } from '../../interfaces.interface';
import { useDirections } from '../../hooks';
import { Character } from '../character/character';
import { usePlayerContext } from '../../context/player-context/player-context';
import { useLayoutContext } from '../../context/layout-context/layout-context';

export const Player = () => {
  const { layoutDidUpdate } = useLayoutContext();
  const { playerPosition, updatePlayerPosition } = usePlayerContext();

  const moveDirection = useDirections(playerPosition, updatePlayerPosition);

  React.useEffect(() => {
    // TODO: ADD LAYOUT CONTEXT
    if (layoutDidUpdate) {
      updatePlayerPosition(CENTER, true /** force */);
    }
  }, [layoutDidUpdate]);

  const moveOnKeypress = React.useCallback(
    throttle(
      (evt: KeyboardEvent) => {
        let _position: ITilePosition = playerPosition;
        switch (evt.keyCode) {
          case KEYCODE.W:
          case KEYCODE.ArrowUp: {
            _position = moveDirection[DIRECTION.UP]();
            break;
          }
          case KEYCODE.A:
          case KEYCODE.ArrowLeft: {
            _position = moveDirection[DIRECTION.LEFT]();
            break;
          }
          case KEYCODE.S:
          case KEYCODE.ArrowDown: {
            _position = moveDirection[DIRECTION.DOWN]();
            break;
          }
          case KEYCODE.D:
          case KEYCODE.ArrowRight: {
            _position = moveDirection[DIRECTION.RIGHT]();
            break;
          }
          default: {
            _position = moveDirection[DIRECTION.NONE]();
            break;
          }
        }
      },
      FRAME_LIMITER_MS,
      { leading: true, trailing: false }
    ),
    [moveDirection, playerPosition]
  );

  React.useEffect(() => {
    window.addEventListener('keydown', moveOnKeypress);

    return () => {
      window.removeEventListener('keydown', moveOnKeypress);
    };
  }, [moveOnKeypress]);

  // console.log(`PLAYER POS: X${playerPos.X}, Y${playerPos.Y}`);

  return <Character color='green' position={playerPosition} />;
};

Player.displayName = 'Player';
