import { ITilePosition, IPXPosition } from './interfaces.interface';
import { Color } from './context/screen-shake-context/screen-shake-context';

export const arePropsEqual = (
  lhs: { [key: string]: any },
  rhs: { [key: string]: any }
) =>
  Object.keys(lhs).length === Object.keys(rhs).length &&
  Object.keys(lhs).every(
    (key) => rhs.hasOwnProperty(key) && lhs[key] === rhs[key]
  );

export const areEntriesSame = (lhs: any[], rhs: any[]) =>
  lhs.length === rhs.length && lhs.forEach((item, idx) => rhs[idx] === item);

export const areValuesSame = (lhs: any, rhs: any) => lhs === rhs;

export const shallowCompare = (lhs: any, rhs: any) => {
  if (typeof lhs === typeof rhs) {
    if (typeof lhs === 'object') {
      return arePropsEqual(lhs, rhs);
    } else if (Array.isArray(lhs)) {
      return areEntriesSame(lhs, rhs);
    } else {
      return areValuesSame(lhs, rhs);
    }
  }

  return false;
};

export const getAbsoluteDistance = (
  posA: ITilePosition,
  posB: ITilePosition
) => {
  return Math.sqrt(Math.pow(posA.X - posB.X, 2) + Math.pow(posA.Y - posB.Y, 2));
};

export const getManhattanDistance = (
  posA: ITilePosition,
  posB: ITilePosition
) => {
  return Math.abs(posA.X - posB.X) + Math.abs(posA.Y - posB.Y);
};

export const FRAME_LIMITER_MS = 50;
export const CHARACTER_DIM = 20;
export const PLAY_AREA_DIM =
  Math.floor(750 / (CHARACTER_DIM * 2)) * CHARACTER_DIM * 2;
export const NUM_GRID_TILES = Math.floor(PLAY_AREA_DIM / CHARACTER_DIM);
export enum CHARACTER {
  PLAYER,
  BEAST,
  WIZARD,
  SHADE,
  CAVE,
  CAVE_BELOW,
  CAVE_INSIDE,
  CAVE_BLACK,
  LIGHT,
}
export const CENTER: ITilePosition = {
  X: Math.floor(NUM_GRID_TILES / 2),
  Y: Math.floor(NUM_GRID_TILES / 2),
  PX: Math.floor(NUM_GRID_TILES / 2),
  PY: Math.floor(NUM_GRID_TILES / 2),
};

/**
 *
 * @param value integer
 * @returns new number in range of [0, value)
 */
export const randMaxInt = (value: number) => Math.floor(Math.random() * value);

/**
 *
 * @param low integer (inclusive)
 * @param high integer (exclusive)
 * @returns new number in range of [low, high)
 */
export const randIntBetween = (low: number, high: number) =>
  Math.floor(Math.random() * (high - low)) + low;

export const isOutsideRadius = (position: ITilePosition, radius: number) =>
  getAbsoluteDistance(position, CENTER) - radius >= 0;

export const calculateFreeSpaces = (
  tiles: boolean[][],
  min: number,
  max: number,
  radius: number
) => {
  // console.log(`iterating over: ${min} * ${max} = ${min * max} tiles...`);
  let count = 0;

  for (let i = min; i < max; ++i) {
    for (let j = min; j < max; ++j) {
      // console.log(`checking tile: ${i}, ${j}`);
      if (isOutsideRadius({ X: j, Y: i }, radius) && !tiles[j][i]) {
        // console.log('free tile found!');
        ++count;
      }
    }
  }

  return count;
};

export const getRandPosition = (
  min: number,
  max: number,
  flatRadius: number
): ITilePosition => {
  // 4 quadrants
  const _section = randMaxInt(4);

  switch (_section) {
    default:
    case 0: {
      return {
        X: randIntBetween(min, CENTER.X - flatRadius),
        Y: randIntBetween(min, max),
      };
    }
    case 1: {
      return {
        X: randIntBetween(min, max),
        Y: randIntBetween(CENTER.Y + flatRadius, max),
      };
    }
    case 2: {
      return {
        X: randIntBetween(CENTER.X + flatRadius, max),
        Y: randIntBetween(min, max),
      };
    }
    case 3: {
      return {
        X: randIntBetween(min, max),
        Y: randIntBetween(min, CENTER.Y - flatRadius),
      };
    }
  }
};

export const isTileFree = (
  tiles: boolean[][],
  position: ITilePosition,
  radius: number
) =>
  getAbsoluteDistance(position, CENTER) - radius >= 0 &&
  !tiles[position.Y][position.X];

export const getRandTile = (
  tiles: boolean[][],
  min: number,
  max: number,
  radius: number,
  flatRadius: number
): ITilePosition => {
  let _position = getRandPosition(min, max, flatRadius);

  // console.log(tiles);
  // console.log(`isTileFree: ${isTileFree(tiles, _position, radius)}`);

  while (!isTileFree(tiles, _position, radius)) {
    _position = getRandPosition(min, max, flatRadius);
  }

  // console.log(`${end - begin}ms`);

  return _position;
};

export const getNextTile = (
  tiles: boolean[][],
  min: number,
  max: number,
  radius: number
): ITilePosition | undefined => {
  // console.log(`iterating over: ${min} * ${max} = ${min * max} tiles...`);
  for (let i = min; i < max; ++i) {
    for (let j = min; j < max; ++j) {
      const checkPos = { X: j, Y: i };
      // console.log(`checking tile: ${i}, ${j}`);
      if (isTileFree(tiles, checkPos, radius)) {
        return checkPos;
      }
    }
  }

  return undefined;
};

export const ColorAnimation: { [key: string]: Color } = {
  PLAYER: 'green',
  BEAST: 'red',
  SHADE: 'black',
  WIZARD: 'blue',
  CAVE: 'brown',
  CAVE_BLACK: 'black',
  LIGHT: 'white',
};

type DIRECTION_KEY = 'UP' | 'DOWN' | 'LEFT' | 'RIGT' | 'NONE';
export enum DIRECTION {
  UP = 'UP',
  DOWN = 'DOWN',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  NONE = 'NONE',
}

export enum KEYCODE {
  W = 87,
  A = 65,
  S = 83,
  D = 68,
  ArrowUp = 38,
  ArrowLeft = 37,
  ArrowDown = 40,
  ArrowRight = 39,
}

export const isTileSame = (
  positionA: ITilePosition,
  positionB: ITilePosition
) => {
  return positionA.X === positionB.X && positionA.Y === positionB.Y;
};

export const getNextPosition = (
  position: ITilePosition,
  direction: DIRECTION
) => {
  switch (direction) {
    case DIRECTION.UP: {
      return { ...position, Y: position.Y + 1 };
    }
    case DIRECTION.DOWN: {
      return { ...position, Y: position.Y - 1 };
    }
    case DIRECTION.LEFT: {
      return { ...position, X: position.X - 1 };
    }
    case DIRECTION.RIGHT: {
      return { ...position, X: position.X + 1 };
    }
    default: {
      return position;
    }
  }
};

export const keyAsDirection = (key: string): DIRECTION => {
  key = (<any>DIRECTION)[key];
  if (key === (DIRECTION[DIRECTION.UP] as string)) {
    return DIRECTION.UP;
  } else if (key === (DIRECTION[DIRECTION.DOWN] as string)) {
    return DIRECTION.DOWN;
  } else if (key === (DIRECTION[DIRECTION.LEFT] as string)) {
    return DIRECTION.LEFT;
  } else if (key === (DIRECTION[DIRECTION.RIGHT] as string)) {
    return DIRECTION.RIGHT;
  } else {
    return DIRECTION.NONE;
  }
};

export const moveTileUp = (position: ITilePosition, distance: number = 1) => ({
  ...position,
  Y: position.Y + distance,
});
export const moveTileDown = (
  position: ITilePosition,
  distance: number = 1
) => ({
  ...position,
  Y: position.Y - distance,
});
export const moveTileRight = (
  position: ITilePosition,
  distance: number = 1
) => ({
  ...position,
  X: position.X + distance,
});
export const moveTileLeft = (
  position: ITilePosition,
  distance: number = 1
) => ({
  ...position,
  X: position.X - distance,
});
export const moveTileNone = (
  position: ITilePosition,
  distance: number = 0
) => ({
  ...position,
});

export const positionToKey = (position?: IPXPosition | ITilePosition) =>
  position ? `${position.X}:${position.Y}` : '';

export const getChaseDirection = (
  positionA: ITilePosition,
  positionB: ITilePosition,
  isCaveTile: (position: ITilePosition) => boolean,
  detectCycle: (position: ITilePosition) => boolean
) => {
  // console.log(positionA, positionB);
  const distances = {
    [DIRECTION.UP]: getAbsoluteDistance(moveTileUp(positionA), positionB),
    [DIRECTION.DOWN]: getAbsoluteDistance(moveTileDown(positionA), positionB),
    [DIRECTION.LEFT]: getAbsoluteDistance(moveTileLeft(positionA), positionB),
    [DIRECTION.RIGHT]: getAbsoluteDistance(moveTileRight(positionA), positionB),
    [DIRECTION.NONE]: getAbsoluteDistance(moveTileNone(positionA), positionB),
  };

  let minDistance = Number.MAX_SAFE_INTEGER;
  let minDirection = DIRECTION.NONE;

  for (let [key, value] of Object.entries(distances)) {
    // console.log(`testing distance: ${key}, ${value}`);
    const nextPosition = getNextPosition(positionA, keyAsDirection(key));
    const _nextPosValue = isCaveTile(nextPosition)
      ? Number.MAX_SAFE_INTEGER
      : value;
    const _nextNextPosValue = isCaveTile(
      getNextPosition(nextPosition, keyAsDirection(key))
    )
      ? Number.MAX_SAFE_INTEGER
      : value;

    const _cyclePosValue = detectCycle(nextPosition)
      ? Number.MAX_SAFE_INTEGER
      : value;

    const _noopPosValue =
      keyAsDirection(key) === DIRECTION.NONE ? Number.MAX_SAFE_INTEGER : value;
    const _attackPosValue = isTileSame(nextPosition, positionB)
      ? Number.MIN_SAFE_INTEGER
      : Number.MAX_SAFE_INTEGER;

    const _adjustedChaseValue = Math.min(
      Math.max(_nextPosValue, _nextNextPosValue, _cyclePosValue, _noopPosValue),
      _attackPosValue
    );

    if (_adjustedChaseValue < minDistance) {
      minDistance = _adjustedChaseValue;
      // console.log(`new max: ${minDirection}`);
      minDirection = keyAsDirection(key);
    }
  }

  // console.log(
  //   `nearest direction: ${minDirection}, with distance: ${minDistance}`
  // );

  return minDirection;
};

export const defer = () => {
  let _resolve, _reject;

  const _promise = new Promise((resolve, reject) => {
    _resolve = resolve;
    _reject = reject;
  });

  (_promise as any).resolve = _resolve;
  (_promise as any).reject = _reject;

  return _promise;
};
