// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".play-area_playArea__2GZKT {\n  background-color: white;\n  position: absolute; }\n", ""]);
// Exports
exports.locals = {
	"playArea": "play-area_playArea__2GZKT"
};
module.exports = exports;
