import * as React from 'react';
import { Title } from '../../assets';
import { Button, ButtonGroup, Tooltip } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import styles from './intro.module.scss';
import {
  useAppContext,
  IGameInfo,
  GameMode,
  GameType,
  Transition,
} from '../../context/app-context/app-context';

const NORMAL_TOOLTIP =
  'Make your way to 8 caves as the world slowly collapses in Shade and the hungry beasts look to you for their last meal.';

const SURVIVAL_TOOLTIP =
  'Survive as long as you can, as the beasts become more and more wild. There is no shade to limit your boundaries.';
const StyledTooltip = withStyles({
  tooltip: {
    maxWidth: 200,
    backgroundColor: 'transparent',
    // outline: '1px solid #f50057',
    boxShadow: 'inset 0 0 3px #f50057',
    textAlign: 'center',
    fontSize: '12px',
    padding: '10px',
  },
  arrow: {
    color: 'rgba(245, 0, 87, 0.5)',
  },
})(Tooltip);
export const Intro = React.memo(() => {
  const { info, startGame } = useAppContext();
  const [playType, setPlayType] = React.useState<Transition>(Transition.MODE);
  const [gameInfo, setGameInfo] = React.useState<IGameInfo>(info);

  const toModeSelect = React.useCallback(() => {
    setPlayType(Transition.MODE);
  }, [setPlayType]);

  const toSingleGame = React.useCallback(() => {
    setPlayType(Transition.TYPE);
    setGameInfo((prev) => ({ ...prev, mode: GameMode.SINGLE }));
  }, [setPlayType]);

  const toMultiGame = React.useCallback(() => {
    setPlayType(Transition.TYPE);
    setGameInfo((prev) => ({ ...prev, mode: GameMode.MULTI }));
  }, [setPlayType]);

  const startNormalGame = React.useCallback(() => {
    startGame(info.mode, GameType.NORMAL);
  }, [startGame, info]);

  const startSurvivalGame = React.useCallback(() => {
    startGame(info.mode, GameType.SURVIVAL);
  }, [startGame, info]);

  const options = React.useMemo(() => {
    switch (playType) {
      case Transition.MODE: {
        return (
          <Button variant='outlined' color='secondary' onClick={toSingleGame}>
            Single Player
          </Button>
        );
      }
      case Transition.TYPE: {
        return (
          <ButtonGroup color='secondary'>
            <StyledTooltip
              arrow
              interactive
              color='secondary'
              title={NORMAL_TOOLTIP}
            >
              <Button
                variant='outlined'
                color='secondary'
                onClick={startNormalGame}
              >
                Normal
              </Button>
            </StyledTooltip>
            <StyledTooltip
              arrow
              interactive
              color='secondary'
              title={SURVIVAL_TOOLTIP}
            >
              <Button
                variant='outlined'
                color='secondary'
                onClick={startSurvivalGame}
              >
                Survival
              </Button>
            </StyledTooltip>
            <Button variant='outlined' color='secondary' onClick={toModeSelect}>
              Back
            </Button>
          </ButtonGroup>
        );
      }
    }
  }, [playType]);

  return (
    <div className={styles.container}>
      <img src={Title} />
      {options}
    </div>
  );
});
