import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app';
import * as serviceWorker from './serviceWorker';
import {
  AppContextProvider,
  ScreenShakeContextProvider,
  PlayerContextProvider,
  CaveContextProvider,
  LayoutContextProvider,
  TextPanelContextProvider,
  ShadeContextProvider,
} from './context';

ReactDOM.render(
  <LayoutContextProvider>
    <ScreenShakeContextProvider>
      <AppContextProvider>
        <CaveContextProvider>
          <ShadeContextProvider>
            <PlayerContextProvider>
              <TextPanelContextProvider>
                <App />
              </TextPanelContextProvider>
            </PlayerContextProvider>
          </ShadeContextProvider>
        </CaveContextProvider>
      </AppContextProvider>
    </ScreenShakeContextProvider>
  </LayoutContextProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
