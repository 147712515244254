import * as React from 'react';
import styles from './fade-behind.module.scss';
import { CHARACTER_DIM, positionToKey } from '../../utility';
import { ITilePosition } from '../../interfaces.interface';
import { useTileToPx } from '../../hooks';
import {
  DurationOrGradient,
  FadeType,
  Color,
} from '../../context/screen-shake-context/screen-shake-context';

const getFadeStyle = (
  type: FadeType,
  color: Color,
  gradient: DurationOrGradient,
  duration: DurationOrGradient
) => styles[`fade-${type}-${color}-${gradient}-${duration}s`];

export const FadeBlock: React.FC<{
  color: Color;
  type: FadeType;
  duration: DurationOrGradient;
  gradient: DurationOrGradient;
  position?: ITilePosition;
  fullscreen?: boolean;
  asRealCoord?: boolean;
  backdrop?: boolean;
}> = React.memo(
  ({
    color,
    position,
    fullscreen,
    backdrop,
    asRealCoord,
    type,
    duration,
    gradient,
  }) => {
    const convertTileToPx = useTileToPx();
    const animClass = getFadeStyle(type, color, gradient, duration);

    const key = React.useMemo(
      () => (position ? positionToKey(position) : +new Date()),
      [position]
    );

    const pxPosition = position ? convertTileToPx(position) : position;

    const style: React.CSSProperties = {
      height: fullscreen ? '100vh' : CHARACTER_DIM,
      width: fullscreen ? '100vw' : CHARACTER_DIM,
      bottom: asRealCoord ? pxPosition?.Y : pxPosition?.PY,
      left: asRealCoord ? pxPosition?.X : pxPosition?.PX,
      position: fullscreen ? 'fixed' : 'absolute',
      zIndex: fullscreen && !backdrop ? 9999 : 'auto',
    };

    return <div key={key} className={animClass} style={style} />;
  }
);

FadeBlock.displayName = 'FadeBlock';
