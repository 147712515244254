// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".intro_container__3AUEo {\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  flex-direction: column;\n  height: 250px;\n  width: 250px;\n  padding: 25px;\n  border-radius: 1%; }\n\n.intro_subContainer__2wVBl {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: row; }\n", ""]);
// Exports
exports.locals = {
	"container": "intro_container__3AUEo",
	"subContainer": "intro_subContainer__2wVBl"
};
module.exports = exports;
