import * as React from 'react';
import { IPXPosition, PxToTileFn, ITilePosition } from './interfaces.interface';
import {
  CHARACTER_DIM,
  DIRECTION,
  PLAY_AREA_DIM,
  shallowCompare,
} from './utility';
import { useAppContext, Transition } from './context/app-context/app-context';

export const useNewGame = (callback: () => void) => {
  const { transition } = useAppContext();

  React.useEffect(() => {
    if (transition === Transition.PRE_GAME_BUILD) {
      callback();
    }
  }, [transition]);
};

export const useMemoizedValue = <T extends object>(value: T) => {
  const valueRef = React.useRef(value);

  if (!shallowCompare(valueRef.current, value)) {
    valueRef.current = value;
  }

  return valueRef.current;
};

export const useDirection = (
  direction: DIRECTION,
  currentPosition: ITilePosition,
  updatePositionFn: (position: ITilePosition) => void
) =>
  React.useCallback((): ITilePosition => {
    let nextPos = currentPosition;
    switch (direction) {
      case DIRECTION.UP: {
        nextPos = {
          ...currentPosition,
          Y: currentPosition.Y + 1,
          PX: currentPosition.X,
          PY: currentPosition.Y,
        };
        break;
      }
      case DIRECTION.DOWN: {
        nextPos = {
          ...currentPosition,
          Y: currentPosition.Y - 1,
          PX: currentPosition.X,
          PY: currentPosition.Y,
        };
        break;
      }
      case DIRECTION.LEFT: {
        nextPos = {
          ...currentPosition,
          X: currentPosition.X - 1,
          PX: currentPosition.X,
          PY: currentPosition.Y,
        };
        break;
      }
      case DIRECTION.RIGHT: {
        nextPos = {
          ...currentPosition,
          X: currentPosition.X + 1,
          PX: currentPosition.X,
          PY: currentPosition.Y,
        };
        break;
      }
      case DIRECTION.NONE: {
        nextPos = currentPosition;
        break;
      }
    }

    updatePositionFn(nextPos);

    return nextPos;
  }, [direction, currentPosition, updatePositionFn]);

export const useDirections = (
  currentPosition: ITilePosition,
  updatePositionFn: (position: ITilePosition) => void
) => ({
  [DIRECTION.UP]: useDirection(DIRECTION.UP, currentPosition, updatePositionFn),
  [DIRECTION.DOWN]: useDirection(
    DIRECTION.DOWN,
    currentPosition,
    updatePositionFn
  ),
  [DIRECTION.LEFT]: useDirection(
    DIRECTION.LEFT,
    currentPosition,
    updatePositionFn
  ),
  [DIRECTION.RIGHT]: useDirection(
    DIRECTION.RIGHT,
    currentPosition,
    updatePositionFn
  ),
  [DIRECTION.NONE]: useDirection(
    DIRECTION.NONE,
    currentPosition,
    updatePositionFn
  ),
});

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );

  const onResize = React.useCallback(() => {
    setWindowDimensions(getWindowDimensions());
  }, [setWindowDimensions]);

  React.useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [onResize]);

  return windowDimensions;
};

export const usePlayAreaBounds = () => {
  const { height: wHeight, width: wWidth } = useWindowDimensions();

  const bottom = React.useMemo(
    () =>
      Math.floor((wHeight - PLAY_AREA_DIM) / (2 * CHARACTER_DIM)) *
      CHARACTER_DIM,
    [wHeight]
  );

  const left = React.useMemo(
    () =>
      Math.floor((wWidth - PLAY_AREA_DIM) / (2 * CHARACTER_DIM)) *
      CHARACTER_DIM,
    [wWidth]
  );

  const top = React.useMemo(() => bottom + PLAY_AREA_DIM, [bottom]);

  const right = React.useMemo(() => left + PLAY_AREA_DIM, [left]);

  // console.log(`PlAY AREA BOUNDS: T${top}, B${bottom}, L${left}, R${right}`);

  return { top, left, bottom, right };
};

export const usePxToTile = (): PxToTileFn => {
  const { left, bottom } = usePlayAreaBounds();

  return React.useCallback(
    (position: IPXPosition) => {
      const tileX = Math.floor(position.X - left) / CHARACTER_DIM;
      const tileY = Math.floor(position.Y - bottom) / CHARACTER_DIM;
      const tilePX =
        Math.floor((position.PX || position.X) - left) / CHARACTER_DIM;
      const tilePY =
        Math.floor((position.PY || position.Y) - bottom) / CHARACTER_DIM;

      return { X: tileX, Y: tileY, PX: tilePX, PY: tilePY };
    },
    [left, bottom]
  );
};

export const useTileToPx = (): PxToTileFn => {
  const { left, bottom } = usePlayAreaBounds();

  return React.useCallback(
    (position: IPXPosition) => {
      const tileX = Math.floor(position.X * CHARACTER_DIM) + left;
      const tileY = Math.floor(position.Y * CHARACTER_DIM) + bottom;
      const tilePX =
        Math.floor((position.PX || position.X) * CHARACTER_DIM) + left;
      const tilePY =
        Math.floor((position.PY || position.Y) * CHARACTER_DIM) + bottom;

      return { X: tileX, Y: tileY, PX: tilePX, PY: tilePY };
    },
    [left, bottom]
  );
};

export const useMountEffect = (
  effect: React.EffectCallback,
  deps?: React.DependencyList | undefined
) => {
  const didMount = React.useRef<boolean>(false);
  React.useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }

    effect();
  }, deps);
};
