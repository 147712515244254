import * as React from 'react';
import styles from './character.module.scss';
import { CHARACTER, ColorAnimation, CHARACTER_DIM } from '../../utility';
import { ITilePosition } from '../../interfaces.interface';
import { useTileToPx } from '../../hooks';
import { FadeBlock } from '../fade-behind/fade-behind';
import { Color } from '../../context/screen-shake-context/screen-shake-context';

interface IBeforeStyle {
  '--left'?: number | string | undefined;
  '--bottom'?: number | string | undefined;
}

export const Character: React.FC<{
  color: Color;
  position: ITilePosition;
  fullscreen?: boolean;
}> = React.memo(({ color, position }) => {
  const convertTileToPx = useTileToPx();
  const keyRef = React.useRef<number>(+new Date());

  React.useEffect(() => {
    keyRef.current = +new Date();
  }, [position]);

  const pxPosition = convertTileToPx(position);

  const style: React.CSSProperties & IBeforeStyle = {
    height: CHARACTER_DIM,
    width: CHARACTER_DIM,
    bottom: pxPosition.Y,
    left: pxPosition.X,
    '--left': `${pxPosition?.PX}px`,
    '--bottom': `${pxPosition?.PY}px`,
  };

  return (
    <div
      className={styles[`character${color}`]}
      style={style}
      key={keyRef.current}
    />
  );
});

Character.displayName = 'Character';
