// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes stats-dashboard_fade-image__1oO8w {\n  0% {\n    opacity: 0%; }\n  1% {\n    opacity: 0%; }\n  100% {\n    opacity: 100%; } }\n\n.stats-dashboard_dashboard__XMsJn {\n  display: flex;\n  position: absolute;\n  display: flex;\n  justify-content: space-evenly;\n  align-items: flex-start;\n  color: white;\n  margin-bottom: 25px; }\n\n.stats-dashboard_item__kyT4m {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column; }\n\n.stats-dashboard_subItem__3k0Dr {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: left;\n  align-items: center;\n  flex-direction: row;\n  min-width: inherit;\n  max-width: inherit; }\n  .stats-dashboard_subItem__3k0Dr > img {\n    animation-name: stats-dashboard_fade-image__1oO8w;\n    animation-duration: 3s; }\n", ""]);
// Exports
exports.locals = {
	"dashboard": "stats-dashboard_dashboard__XMsJn",
	"item": "stats-dashboard_item__kyT4m",
	"subItem": "stats-dashboard_subItem__3k0Dr",
	"fade-image": "stats-dashboard_fade-image__1oO8w"
};
module.exports = exports;
