import * as React from 'react';
import { ITilePosition } from '../../interfaces.interface';
import { FadeBlock } from '../fade-behind/fade-behind';
import {
  CHARACTER,
  positionToKey,
  FRAME_LIMITER_MS,
  calculateFreeSpaces,
  getRandTile,
  getNextTile,
} from '../../utility';
import { throttle, fill } from 'lodash';
import { usePlayerContext } from '../../context/player-context/player-context';
import { useLayoutContext } from '../../context/layout-context/layout-context';
import { useMountEffect, useNewGame } from '../../hooks';
import { useShadeContext } from '../../context/shade-context/shade-context';
import {
  useAppContext,
  GameType,
  Transition,
  SHADE_MAP_READY,
} from '../../context/app-context/app-context';

export const Shade: React.FC<{ position: ITilePosition }> = React.memo(
  ({ position }) => {
    // console.log(`rendering shade at: ${pxPosition.X}, ${pxPosition.Y}`);

    return (
      <FadeBlock
        color='black'
        duration={10}
        gradient={10}
        type='forward'
        position={position}
      />
    );
  }
);

Shade.displayName = 'Shade';

export const ShadeMap = () => {
  const { playerPosition } = usePlayerContext();
  const {
    info: { type },
    transition,
    setReady,
  } = useAppContext();
  const { nextTile, spawnShade } = useShadeContext();
  const [shades, setShades] = React.useState<ITilePosition[]>([]);

  useNewGame(() => {
    setShades([]);
  });

  React.useEffect(() => {
    if (shades.length === 0 && transition === Transition.PRE_GAME_BUILD) {
      setReady(SHADE_MAP_READY);
    }
  }, [shades, transition]);

  const addShade = React.useCallback(() => {
    const newTile = nextTile();
    if (newTile) {
      // console.log(
      //   `new shade: ${newTile ? `X${newTile.X}, Y${newTile.Y}` : ''}`
      // );
      setShades((prev) => {
        // console.log(
        //   `pushed shade: ${position ? `X${position.X}, Y${position.Y}` : ''}`
        // );
        return [...prev, newTile];
      });
      spawnShade(newTile);
    }
  }, [setShades, spawnShade, nextTile]);

  useMountEffect(() => {
    if (type === GameType.NORMAL && transition === Transition.GAME_ACTIVE) {
      addShade();
    }
  }, [playerPosition, addShade, type, transition]);

  const shadesMap = React.useMemo(() => {
    return shades.map((shadePos) => {
      // console.log(shadePos);
      return <Shade key={positionToKey(shadePos)} position={shadePos} />;
    });
  }, [shades]);

  return <>{shadesMap}</>;
};

ShadeMap.displayName = 'ShadeMap';
