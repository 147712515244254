import * as React from 'react';
import { usePlayerContext } from '../../context/player-context/player-context';
import styles from './stats-dashboard.module.scss';
import { CHARACTER_DIM } from '../../utility';
import { useLayoutContext } from '../../context/layout-context/layout-context';
import { useCaveContext } from '../../context/cave-context/cave-context';
import { Heart, Cave } from '../../assets';

export const StatsDashboard = () => {
  const { playerHealth } = usePlayerContext();
  const { maxAreaTop, maxAreaLeft, playAreaLength } = useLayoutContext();
  const { cavesEntered } = useCaveContext();

  const hearts = React.useMemo(() => {
    if (playerHealth < 0) {
      return null;
    }
    return Array(playerHealth)
      .fill(1)
      .map((i, idx) => {
        return (
          <img
            key={`heart-${idx}`}
            src={Heart}
            style={{ height: CHARACTER_DIM, width: CHARACTER_DIM }}
          />
        );
      });
  }, [playerHealth]);

  const caves = React.useMemo(() => {
    if (cavesEntered < 0) {
      return null;
    }
    return Array(cavesEntered)
      .fill(1)
      .map((i, idx) => {
        return (
          <img
            key={`cave-${idx}`}
            src={Cave}
            style={{ height: CHARACTER_DIM, width: CHARACTER_DIM }}
          />
        );
      });
  }, [cavesEntered]);

  return (
    <div
      className={styles.dashboard}
      style={{
        bottom: maxAreaTop,
        left: maxAreaLeft,
        width: playAreaLength,
        minHeight: 5 * CHARACTER_DIM,
      }}
    >
      <div
        className={styles.item}
        style={{ minWidth: CHARACTER_DIM * 5, maxWidth: CHARACTER_DIM * 5 }}
      >
        <p>Life</p>
        <div className={styles.subItem}>{hearts}</div>
      </div>
      <div
        className={styles.item}
        style={{ minWidth: CHARACTER_DIM * 5, maxWidth: CHARACTER_DIM * 5 }}
      >
        <p>Caves</p>
        <div className={styles.subItem}>{caves}</div>
      </div>
    </div>
  );
};
