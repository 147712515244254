import * as React from 'react';
import styles from './text-panel.module.scss';
import { useTextPanelContext } from '../../context/text-panel-context/text-panel-context';
import { useLayoutContext } from '../../context/layout-context/layout-context';

export const TextPanel = React.memo(() => {
  const { maxAreaTop, maxAreaLeft, playAreaLength } = useLayoutContext();
  const { text } = useTextPanelContext();
  const keyRef = React.useRef<number>(0);

  const textStrings = React.useMemo(
    () => text.map((_str, idx) => <p key={`text-${idx}`}>{_str}</p>),
    [text]
  );

  React.useEffect(() => {
    keyRef.current = +new Date();
  }, [text]);

  return (
    <div
      className={styles.textPanel}
      style={{
        top: maxAreaTop,
        left: maxAreaLeft,
        width: playAreaLength,
      }}
      key={keyRef.current}
    >
      {textStrings}
    </div>
  );
});
