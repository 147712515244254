import * as React from 'react';
import { useMemoizedValue, useNewGame } from '../../hooks';
import { NUM_GRID_TILES, getAbsoluteDistance, CENTER } from '../../utility';
import { ITilePosition } from '../../interfaces.interface';
import { useLayoutContext } from '../layout-context/layout-context';
import {
  useAppContext,
  Transition,
  SHADE_CONTEXT_READY,
} from '../app-context/app-context';
import { shuffle } from 'lodash';

interface IShadeContext {
  spawnShade: (position: ITilePosition) => void;
  tileHasShade: (position: ITilePosition) => boolean;
  nextTile: () => ITilePosition | undefined;
}

const ShadeContext = React.createContext<IShadeContext>({
  spawnShade: (_: ITilePosition) => {},
  tileHasShade: (_: ITilePosition) => false,
  nextTile: () => undefined,
});

export const useShadeContext = () => React.useContext(ShadeContext);

const generateShadeArray = () =>
  [...Array(NUM_GRID_TILES)].map((_) => Array(NUM_GRID_TILES).fill(false));

interface ITileMap {
  section: number;
  [key: number]: ITilePosition[];
  pop: () => ITilePosition | undefined;
}

const generateTileMap = (): ITileMap => {
  const tileMap: ITileMap = {
    section: 0,
    pop: function () {
      const _len = this[this.section]?.length;

      if (_len > 1) {
        return this[this.section].pop();
      } else if (_len === 1) {
        const tile = this[this.section].pop();
        ++this.section;
        return tile;
      }

      return undefined;
    },
  };
  const tileRef = generateShadeArray();
  let radius: number = Math.floor(NUM_GRID_TILES / 2) - 1;
  let entries: number = 0;
  let section = 0;

  while (entries < Math.pow(NUM_GRID_TILES, 2)) {
    for (let i: number = 0; i < NUM_GRID_TILES; ++i) {
      for (let j: number = 0; j < NUM_GRID_TILES; ++j) {
        const entry: ITilePosition = { X: j, Y: i };

        if (!tileRef[i][j] && getAbsoluteDistance(entry, CENTER) >= radius) {
          tileRef[i][j] = true;
          entries++;

          if (!tileMap[section]) {
            tileMap[section] = [entry];
          } else {
            tileMap[section].push(entry);
          }
        }
      }
    } // end iterate 2d array

    // randomize the section array so that later pop is O(1)
    tileMap[section] = shuffle(tileMap[section]);

    section++;

    if (radius > 0) {
      radius--;
    }
  }

  return tileMap;
};

export const ShadeContextProvider: React.FC<{
  children: React.ReactChild | React.ReactChild[];
}> = React.memo(({ children }) => {
  const shadeMap = React.useRef<boolean[][]>([]);
  const tileMap = React.useRef<ITileMap | undefined>(undefined);

  const { transition, setReady } = useAppContext();

  useNewGame(() => {
    shadeMap.current = generateShadeArray();
    tileMap.current = generateTileMap();
  });

  React.useEffect(() => {
    if (
      tileMap.current?.section === 0 &&
      transition === Transition.PRE_GAME_BUILD
    ) {
      setReady(SHADE_CONTEXT_READY);
    }
  }, [tileMap, transition]);

  const tileHasShade = React.useCallback(
    (position: ITilePosition) => {
      return shadeMap.current[position.Y][position.X];
    },
    [shadeMap]
  );

  const spawnShade = React.useCallback(
    (position: ITilePosition) => {
      shadeMap.current[position.Y][position.X] = true;

      // console.log(`added shade: X${position.X}, Y${position.Y}`);
    },
    [shadeMap]
  );

  const nextTile = React.useCallback(() => {
    return tileMap.current?.pop();
  }, [tileMap]);

  return (
    <ShadeContext.Provider
      value={useMemoizedValue({
        spawnShade,
        tileHasShade,
        nextTile,
      })}
    >
      {children}
    </ShadeContext.Provider>
  );
});
