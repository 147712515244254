import * as React from 'react';
import { ITilePosition } from '../../interfaces.interface';
import { useDirections, useMountEffect, useNewGame } from '../../hooks';
import {
  CHARACTER,
  getChaseDirection,
  isTileSame,
  positionToKey,
  getRandPosition,
} from '../../utility';
import { useScreenShakeContext } from '../../context/screen-shake-context/screen-shake-context';
import { debounce } from 'lodash';
import { Character } from '../character/character';
import { usePlayerContext } from '../../context/player-context/player-context';
import { useCaveContext } from '../../context/cave-context/cave-context';
import { useLayoutContext } from '../../context/layout-context/layout-context';
import {
  useAppContext,
  Transition,
  BEAST_READY,
} from '../../context/app-context/app-context';

export const Beast: React.FC<{
  bKey: number;
  onChase: (
    key: number,
    newPosition: ITilePosition,
    oldPosition: ITilePosition
  ) => void;
}> = React.memo(({ bKey, onChase }) => {
  const { isCaveTile } = useCaveContext();
  const { playerPosition, playerMoveCount, isPlayerTile } = usePlayerContext();
  const { playAreaTileLength } = useLayoutContext();
  const beastPosRef = React.useRef<ITilePosition>(
    getRandPosition(
      0,
      playAreaTileLength,
      Math.floor(playAreaTileLength / 2 - 1)
    )
  );
  const beastPosLookback = React.useRef<ITilePosition[]>([beastPosRef.current]);
  const [beastPos, updateBeastPos] = React.useState<ITilePosition>(
    beastPosRef.current
  );

  const detectCycle = React.useCallback(
    (position: ITilePosition) => {
      let cycleDetected = false;
      if (beastPosLookback.current.length === 2) {
        if (isTileSame(beastPosLookback.current[0], position)) {
          cycleDetected = true;
        }
      }

      return cycleDetected;
    },
    [beastPosLookback]
  );

  const addBeastMoveLookback = React.useCallback(
    (position: ITilePosition) => {
      if (beastPosLookback.current.length == 2) {
        beastPosLookback.current.shift();
      }

      beastPosLookback.current.push(position);
    },
    [beastPosLookback]
  );

  const moveBeast = React.useCallback(
    (position: ITilePosition) => {
      if (isTileSame(beastPosRef.current, position) && isPlayerTile(position)) {
        onChase(bKey, position, position);
      }
      if (playerMoveCount % 2 === 0) {
        if (!isTileSame(beastPosRef.current, position)) {
          const _old = beastPosRef.current;
          beastPosRef.current = position;
          updateBeastPos(position);
          onChase(bKey, position, _old);
        }
        addBeastMoveLookback(position);
      }
    },
    [
      beastPosRef,
      updateBeastPos,
      onChase,
      bKey,
      addBeastMoveLookback,
      playerMoveCount,
    ]
  );

  const moveDirection = useDirections(beastPosRef.current, moveBeast);

  const chasePlayer = React.useCallback(() => {
    const chaseDirection = getChaseDirection(
      beastPosRef.current,
      playerPosition,
      isCaveTile,
      detectCycle
    );

    moveDirection[chaseDirection]();
  }, [playerPosition, moveDirection, beastPosRef, isCaveTile, detectCycle]);

  useMountEffect(() => {
    chasePlayer();
  }, [playerPosition]);

  return <Character color='red' position={beastPos} />;
});

Beast.displayName = 'Beast';

export const BeastMap = React.memo(() => {
  const { transition, setReady } = useAppContext();
  const { playerMoveCount, isPlayerTile, attackPlayer } = usePlayerContext();
  const { isCaveEntered } = useCaveContext();
  const isMounted = React.useRef<boolean>(true);
  const beastsRef = React.useRef<number[]>([]);
  const beastsLocationRef = React.useRef<{ [key: string]: number[] }>({});
  const deadBeastsRef = React.useRef<number[]>([]);
  const [beasts, updateBeasts] = React.useState<number[]>([]);

  useNewGame(() => {
    updateBeasts([]);
    beastsRef.current = [];
    beastsLocationRef.current = {};
  });

  React.useEffect(() => {
    if (beasts.length === 0 && transition === Transition.PRE_GAME_BUILD) {
      setReady(BEAST_READY);
    }
  }, [beasts, transition]);

  const spawnBeast = React.useCallback(() => {
    updateBeasts((prev) => {
      const _beastKey = +new Date();

      prev.push(_beastKey);

      beastsRef.current = [...prev];
      // beastsKeyRef.current[_beastKey] = 1;

      return beastsRef.current;
    });
  }, [updateBeasts, beastsRef]);

  const removeBeastSettle = React.useMemo(
    () =>
      debounce(
        () => {
          if (!isMounted.current) {
            return;
          }
          // console.log(
          //   'removeBeastSettle',
          //   JSON.stringify(beastsRef.current, undefined, 2)
          // );
          updateBeasts(beastsRef.current);
        },
        10,
        { leading: false, trailing: true }
      ),
    [updateBeasts, beastsRef]
  );

  const removeBeast = React.useCallback(
    (bKey: number) => {
      beastsRef.current = beastsRef.current.filter((key) => {
        if (key !== bKey && !(key in deadBeastsRef.current)) {
          return true;
        } else {
          // console.log(`REMOVING BEAST: ${bKey}`);
        }
      });

      removeBeastSettle();
    },
    [removeBeastSettle, beastsRef]
  );

  const onChaseSettle = React.useMemo(
    () =>
      debounce(
        () => {
          if (!isMounted.current) {
            return;
          }
          // console.log(
          //   'onChaseSettle',
          //   JSON.stringify(beastsLocationRef.current, undefined, 2),
          //   JSON.stringify(deadBeastsRef.current, undefined, 2)
          // );
          attackPlayer(deadBeastsRef.current.length, 'red', { duration: 1 });
          while (deadBeastsRef.current.length > 0) {
            const entry = deadBeastsRef.current.pop();
            if (entry) {
              removeBeast(entry);
            }
          }
          for (let [key, value] of Object.entries(beastsLocationRef.current)) {
            while (value.length > 1) {
              const entry = beastsLocationRef.current[key].pop();
              // console.log('Removing duplicate beast from position', key);
              if (entry) {
                removeBeast(entry);
              }
            }
          }
        },
        10,
        { leading: false, trailing: true }
      ),
    [removeBeast, beastsLocationRef, attackPlayer]
  );

  const onChase = React.useCallback(
    (bKey: number, newPosition: ITilePosition, oldPosition: ITilePosition) => {
      const newPositionKey = positionToKey(newPosition);
      const oldPositionKey = positionToKey(oldPosition);
      // console.log(bKey, `beastPos`, newPosition, 'playerPos', playerPosition);

      if (isPlayerTile(newPosition)) {
        // console.log(
        //   `${bKey} ATTACK ${JSON.stringify(
        //     oldPosition,
        //     undefined,
        //     2
        //   )} => ${JSON.stringify(newPosition, undefined, 2)}`
        // );

        deadBeastsRef.current.push(bKey);
      } else {
        if (!beastsLocationRef.current[newPositionKey]) {
          beastsLocationRef.current[newPositionKey] = [bKey];
        } else {
          beastsLocationRef.current[newPositionKey].push(bKey);
          // console.log(
          //   `${bKey} COLLISION`,
          //   JSON.stringify(
          //     beastsLocationRef.current[newPositionKey],
          //     undefined,
          //     2
          //   )
          // );
        }
      }

      if (beastsLocationRef.current[oldPositionKey]) {
        beastsLocationRef.current[oldPositionKey] = beastsLocationRef.current[
          oldPositionKey
        ].filter((_bKey) => _bKey !== bKey);

        if (beastsLocationRef.current[oldPositionKey].length === 0) {
          delete beastsLocationRef.current[oldPositionKey];
        }
      }

      onChaseSettle();
    },
    [beastsLocationRef, onChaseSettle, isPlayerTile]
  );

  const shouldBeastSpawn = React.useMemo(() => {
    return (
      playerMoveCount % Math.max(6 - Math.floor(playerMoveCount / 100), 1) === 0
    );
  }, [playerMoveCount]);

  React.useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useMountEffect(() => {
    if (
      shouldBeastSpawn &&
      !isCaveEntered &&
      transition === Transition.GAME_ACTIVE
    ) {
      spawnBeast();
    }

    if (isCaveEntered) {
      updateBeasts([]);
      beastsLocationRef.current = {};
      beastsRef.current = [];
      deadBeastsRef.current = [];
    }
  }, [spawnBeast, playerMoveCount, isCaveEntered, transition]);

  const beastsMap = React.useMemo(() => {
    // console.log('Generate beast map', JSON.stringify(beasts, undefined, 2));
    return beasts.map((key) => {
      return <Beast onChase={onChase} bKey={key} key={key} />;
    });
  }, [beasts]);

  return <>{beastsMap}</>;
});

BeastMap.displayName = 'BeastMap';
