import * as React from 'react';
import { useMemoizedValue } from '../../hooks';
import { useScreenShakeContext } from '../screen-shake-context/screen-shake-context';

export enum GameMode {
  SINGLE,
  MULTI,
}

export enum GameType {
  NORMAL,
  SURVIVAL,
}

export enum Transition {
  MODE = 1,
  TYPE = 2,
  PRE_GAME_INIT = 3,
  PRE_GAME_BUILD = 4,
  GAME_START = 5,
  GAME_ACTIVE = 6,
  POST_GAME = 7,
  END = 8,
}

export interface IGameInfo {
  mode: GameMode;
  type: GameType;
}

type READY_FLAG = 0b001 | 0b010 | 0b100 | 0b1000;
export const SHADE_CONTEXT_READY = 0b0001;
export const SHADE_MAP_READY = 0b0010;
export const BEAST_READY = 0b0100;
export const PLAYER_READY = 0b1000;
const GAME_READY = 0b1111;

interface IAppContext {
  info: IGameInfo;
  transition: Transition;
  gameReady: boolean;
  setReady: (phase: READY_FLAG) => void;
  startGame: (mode: GameMode, type: GameType) => void;
  onGameActive: () => void;
  resetGame: () => void;
}

const AppContext = React.createContext<IAppContext>({
  info: {
    mode: GameMode.SINGLE,
    type: GameType.NORMAL,
  },
  transition: Transition.MODE,
  gameReady: false,
  setReady: (_phase: READY_FLAG) => {},
  startGame: (_mode: GameMode, _type: GameType) => {},
  onGameActive: () => {},
  resetGame: () => {},
});

export const useAppContext = () => React.useContext(AppContext);

export const AppContextProvider: React.FC<{
  children: React.ReactChild | React.ReactChild[];
}> = React.memo(({ children }) => {
  const { shakeColor } = useScreenShakeContext();
  const [gameReady, setGameReady] = React.useState<boolean>(false);
  const [info, setGameInfo] = React.useState<IGameInfo>({
    mode: GameMode.SINGLE,
    type: GameType.NORMAL,
  });
  const gameReadyRef = React.useRef<number>(0b00);

  const [transition, _setTransition] = React.useState<Transition>(
    Transition.MODE
  );

  const setTransition = React.useCallback(
    (_transition: Transition) => {
      console.log(Transition[_transition]);
      if (_transition !== transition) {
        _setTransition(_transition);
      }
    },
    [_setTransition, transition]
  );

  React.useEffect(() => {
    if (transition === Transition.PRE_GAME_INIT) {
      gameReadyRef.current = 0b00;
      setGameReady(false);
      setTransition(Transition.PRE_GAME_BUILD);
    }
  }, [gameReady, transition, setGameReady]);

  React.useEffect(() => {
    if (gameReady && transition === Transition.PRE_GAME_BUILD) {
      setTransition(Transition.GAME_START);
    }
  }, [gameReady, transition, setTransition]);

  const onGameActive = React.useCallback(() => {
    setTransition(Transition.GAME_ACTIVE);
  }, [setTransition]);

  const setReady = React.useCallback(
    (phase: READY_FLAG) => {
      gameReadyRef.current = gameReadyRef.current | phase;

      if (gameReadyRef.current === GAME_READY) {
        setGameReady(true);
      }
    },
    [setGameReady]
  );

  const startGame = React.useCallback(
    (mode: GameMode, type: GameType) => {
      setGameInfo({
        mode,
        type,
      });
      setTransition(Transition.PRE_GAME_INIT);
    },
    [setGameInfo]
  );

  const resetGame = React.useCallback(() => {
    shakeColor('black', {
      duration: 2,
      type: 'forward',
      callback: () => setTransition(Transition.MODE),
    });

    // setTransition(Transition.MODE);
  }, [setTransition]);

  return (
    <AppContext.Provider
      value={useMemoizedValue({
        info,
        transition,
        gameReady,
        onGameActive,
        setReady,
        startGame,
        resetGame,
      })}
    >
      {children}
    </AppContext.Provider>
  );
});
