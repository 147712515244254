import * as React from 'react';
import { FadeBlock } from '../fade-behind/fade-behind';
import {
  moveTileLeft,
  CENTER,
  moveTileUp,
  moveTileRight,
  CHARACTER,
  positionToKey,
} from '../../utility';
import { Character } from '../character/character';
import {
  BrownTilesAbove,
  BlackTilesAbove,
  useCaveContext,
  BrownTilesBelow,
  BrownTilesInside,
  CaveLightTilesInside,
} from '../../context/cave-context/cave-context';
import { Wizard } from '../wizard/wizard';

export const Cave = React.memo(() => {
  const { isCaveVisible, isCaveEntered } = useCaveContext();
  const brownTiles = React.useMemo(() => {
    if (isCaveVisible) {
      return BrownTilesAbove.map((pos) => (
        <FadeBlock
          position={pos}
          color='brown'
          key={positionToKey(pos)}
          asRealCoord={true}
          gradient={isCaveEntered ? 5 : 10}
          duration={10}
          type='forward'
        />
      ));
    }

    return null;
  }, [isCaveVisible, isCaveEntered]);
  const blackTiles = React.useMemo(() => {
    if (isCaveVisible) {
      return BlackTilesAbove.map((pos) => (
        <FadeBlock
          position={pos}
          color={isCaveEntered ? 'white' : 'black'}
          key={positionToKey(pos)}
          asRealCoord={true}
          gradient={isCaveEntered ? 1 : 10}
          duration={10}
          type='forward'
        />
      ));
    }

    return null;
  }, [isCaveVisible, isCaveEntered]);

  const caveTilesBelow = React.useMemo(() => {
    if (isCaveEntered) {
      return BrownTilesBelow.map((pos) => (
        <FadeBlock
          position={pos}
          color='brown'
          key={positionToKey(pos)}
          asRealCoord={true}
          gradient={10}
          duration={10}
          type='forward'
        />
      ));
      // console.log('SPAWNING CAVE BELOW');
    }

    return null;
  }, [isCaveEntered]);

  const caveTilesInside = React.useMemo(() => {
    if (isCaveEntered) {
      return CaveLightTilesInside.map((pos) =>
        !pos ? null : (
          <FadeBlock
            position={pos}
            color='white'
            key={positionToKey(pos)}
            asRealCoord={true}
            gradient={5}
            duration={10}
            type='forward'
          />
        )
      ).filter(Boolean);
      // console.log('SPAWNING CAVE BELOW');
    }

    return null;
  }, [isCaveEntered]);

  return (
    <>
      {brownTiles}
      {blackTiles}
      {caveTilesBelow}
      {caveTilesInside}
      <Wizard />
    </>
  );
});
