import * as React from 'react';
import { CHARACTER } from '../../utility';
import {
  useCaveContext,
  WIZARD_TILE,
} from '../../context/cave-context/cave-context';
import { FadeBlock } from '../fade-behind/fade-behind';
import { useMountEffect } from '../../hooks';
import { useTextPanelContext } from '../../context/text-panel-context/text-panel-context';

const WIZARD_TEXT: string[][] = [
  [`Wizard: "Leave now."`],
  [`Wizard: "Look at them run. See how scared they are?"`],
  [`Wizard: "You look tried. Why don't you rest?"`],
  [`Wizard: "The shade is getting so close."`],
  [`Wizard: "Are you afraid of the dark?"`, `"I am."`],
  [`Wizard: "Do you think they are dumb? They are just like us."`],
  [`Wizard: "Why don't you keep me company, before we depart?"`],
  [`...\n\nA cool wind blows.`],
];

export const Wizard = () => {
  const { isCaveEntered, wizardsEncountered } = useCaveContext();
  const { updateText } = useTextPanelContext();

  useMountEffect(() => {
    if (wizardsEncountered > 0) {
      updateText(WIZARD_TEXT[wizardsEncountered - 1]);
    }
  }, [wizardsEncountered]);

  return !isCaveEntered ? null : (
    <FadeBlock
      color='blue'
      position={WIZARD_TILE}
      asRealCoord
      gradient={10}
      duration={10}
      type='forward'
    />
  );
};
