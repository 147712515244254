import * as React from 'react';
import styles from './play-area.module.scss';
import { useWindowDimensions } from '../../hooks';
import { PLAY_AREA_DIM, CHARACTER_DIM } from '../../utility';

export const PlayArea: React.FC<{
  children?: React.ReactChild | React.ReactChild[];
}> = React.memo(({ children }) => {
  const { height: wHeight, width: wWidth } = useWindowDimensions();

  const fromXY = React.useMemo(
    () => ({
      bottom:
        Math.floor((wHeight - PLAY_AREA_DIM) / (2 * CHARACTER_DIM)) *
        CHARACTER_DIM,
      left:
        Math.floor((wWidth - PLAY_AREA_DIM) / (2 * CHARACTER_DIM)) *
        CHARACTER_DIM,
    }),
    [wHeight, wWidth]
  );

  return (
    <div
      className={styles.playArea}
      style={{ height: PLAY_AREA_DIM, width: PLAY_AREA_DIM, ...fromXY }}
    >
      {children}
    </div>
  );
});
